import axios from 'axios';
import store from '../store';

class IntegrationService {
    public async loadConfig() {
        const currentState = store.getState();
        const { activity_uuid } = currentState.integration;

        return axios.get(
            'https://bypass.2035.university/api/activity/' + activity_uuid + '/get_config',
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                params: {},
            }
        );
    }

    public async loadResult() {
        const currentState = store.getState();
        const source_activity_uuid = currentState.widget.config.source_activity_uuid;

        const { unti_id, token } = currentState.integration;

        return axios.post(
            `https://bypass.2035.university/api/activity/${source_activity_uuid}/load`,
            {
                unti_id: unti_id,
                token: token,
                t: 'c8d46d341bea4fd5bff866a65ff8aea9',
            }
        );
    }
}

const integrationService = new IntegrationService();

export default integrationService;
