import React, { useEffect } from 'react';
import { Result } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import AppState from '../types/AppState';
import { setIntegrationParams } from '../store/integration/integrationReducer';
import urlParams from '../services/urlParams';

const IntegrationParamsComponent: React.FC<any> = ({ component: Component, allowed, ...rest }) => {
    const dispatch = useDispatch();
    const { integration } = useSelector((state: AppState) => state);
    const { token, unti_id, activity_uuid } = integration;

    const rootId = document.getElementById('lifejourney-results')
        ? 'lifejourney-results'
        : 'webbot';
    const root = document.getElementById(rootId);

    useEffect(() => {
        let isFramed;
        try {
            isFramed =
                window !== window.top || // eslint-disable-next-line no-restricted-globals
                document !== top!.document || // eslint-disable-next-line no-restricted-globals
                self.location !== top!.location; // eslint-disable-next-line no-restricted-globals
        } catch (e) {
            isFramed = true;
        }

        if (isFramed) {
            let iframeParams = urlParams.getUrlParams(window.location.href);

            const token = iframeParams.token,
                unti_id = iframeParams.unti_id,
                activity_uuid = iframeParams.activity_uuid;

            dispatch(
                setIntegrationParams({
                    token: token,
                    unti_id: unti_id,
                    activity_uuid: activity_uuid,
                })
            );
        } else {
            if (root) {
                const token = root.getAttribute('data-user-token'),
                    unti_id = root.getAttribute('data-unti-id'),
                    activity_uuid = root.getAttribute('data-activity-uuid');

                dispatch(
                    setIntegrationParams({
                        token: token,
                        unti_id: unti_id,
                        activity_uuid: activity_uuid,
                    })
                );
            }
        }
    }, [dispatch, root]);

    if (token && unti_id && activity_uuid) {
        return (
            <Component {...rest} token={token} unti_id={unti_id} activity_uuid={activity_uuid} />
        );
    } else {
        return <Result status="warning" title="Извините, произошла ошибка. Возвращайтесь позже." />;
    }
};

export default IntegrationParamsComponent;
