import { createSlice } from '@reduxjs/toolkit';
import WidgetState from '../../types/WidgetState';

const initialState: WidgetState = {
    result: {
        education: [],
        career: [],
        hobby: [],
        achievements: [],
        remarkableEvents: [],
        insights: [],
    },
    config: {
        source_activity_uuid: undefined,
        edit_link: '',
    },
    fetchResult: false,
    fetchResultFailed: false,
    fetchConfig: true,
    fetchConfigFailed: false,
};

const widgetSlice = createSlice({
    name: 'widget',
    initialState,
    reducers: {
        loadResult: (state) => {
            state.fetchResult = true;
        },
        loadResultFailed: (state) => {
            state.fetchResult = false;
            state.fetchResultFailed = true;
        },
        loadResultSuccess: (state, action) => {
            state.result = action.payload;
            state.fetchResult = false;
        },
        loadConfig: (state) => {
            state.fetchConfig = true;
        },
        loadConfigFailed: (state) => {
            state.fetchConfig = false;
            state.fetchConfigFailed = true;
        },
        loadConfigSuccess: (state, action) => {
            state.config = action.payload;
            state.fetchConfig = false;
        },
    },
});

const { actions, reducer } = widgetSlice;

export const {
    loadResult,
    loadResultFailed,
    loadResultSuccess,
    loadConfig,
    loadConfigFailed,
    loadConfigSuccess,
} = actions;

export default reducer;
