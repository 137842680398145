import { createSlice } from '@reduxjs/toolkit';
import Integration from '../../types/Integration';

const initialState: Integration = {
    token: '',
    unti_id: '',
    activity_uuid: '',
};

const integrationSlice = createSlice({
    name: 'integration',
    initialState,
    reducers: {
        setIntegrationParams: (state, action) => {
            state.token = action.payload.token;
            state.unti_id = action.payload.unti_id;
            state.activity_uuid = action.payload.activity_uuid;
        },
    },
});

const { actions, reducer } = integrationSlice;

export const { setIntegrationParams } = actions;

export default reducer;
