import React, { useEffect, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Visualization from '../Visualization/Visualization';

function Preview() {
    const [move, setMove] = useState(-30);

    useEffect(() => {
        let content = document.getElementsByClassName(
            'life-journey-widget-preview-overlay__content'
        ) as HTMLCollectionOf<HTMLElement>;

        content[0].style.transform = 'translateX(' + move + 'px)';
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const prev = () => {
        let content = document.getElementsByClassName(
            'life-journey-widget-preview-overlay__content'
        ) as HTMLCollectionOf<HTMLElement>;

        if (move > 0) return;

        let tMove = move + 200;
        setMove(tMove);

        content[0].style.transform = 'translateX(' + tMove + 'px)';
    };

    const next = () => {
        let content = document.getElementsByClassName(
            'life-journey-widget-preview-overlay__content'
        ) as HTMLCollectionOf<HTMLElement>;
        let picture = document.getElementsByClassName(
            'life-journey-widget-preview__picture'
        ) as HTMLCollectionOf<HTMLElement>;

        if (move < picture[0].offsetWidth * -1 - content[0].offsetWidth * -1) return;

        let tMove = move - 200;
        setMove(tMove);

        content[0].style.transform = 'translateX(' + tMove + 'px)';
    };

    return (
        <div className="life-journey-widget-preview">
            <div className="life-journey-widget-preview-overlay">
                <div className="life-journey-widget-preview-overlay__content">
                    <Visualization />
                </div>
            </div>
            <div
                className="life-journey-widget-preview__arrow life-journey-widget-preview__arrow_prev"
                onClick={prev}
            >
                <LeftOutlined style={{ fontSize: '16px', color: '#fff' }} />
            </div>
            <div
                className="life-journey-widget-preview__arrow life-journey-widget-preview__arrow_next"
                onClick={next}
            >
                <RightOutlined style={{ fontSize: '16px', color: '#fff' }} />
            </div>
        </div>
    );
}

export default Preview;
