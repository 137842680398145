import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spin from 'antd/es/spin';
import Empty from 'antd/es/empty';
import integrationService from './services/integrationService';
import AppState from './types/AppState';
import Preview from './components/Preview/Preview';
import {
    loadConfig,
    loadConfigFailed,
    loadConfigSuccess,
    loadResult,
    loadResultFailed,
    loadResultSuccess,
} from './store/widget/widgetReducer';
import { Button } from 'antd';

function App() {
    const dispatch = useDispatch();
    const { fetchConfig, fetchConfigFailed, fetchResult, fetchResultFailed, result, config } =
        useSelector((state: AppState) => state.widget);

    useEffect(() => {
        (async () => {
            try {
                dispatch(loadConfig());
                const loadConfigRequest = await integrationService.loadConfig();
                dispatch(loadConfigSuccess(loadConfigRequest.data));
            } catch (error) {
                dispatch(loadConfigFailed());
                console.log(error);
            }

            try {
                dispatch(loadResult());
                const loadResultRequest = await integrationService.loadResult();

                dispatch(
                    loadResultSuccess(
                        Object.keys(loadResultRequest.data.value).length > 0 &&
                            loadResultRequest.data.value !== '{}'
                            ? loadResultRequest.data.value
                            : undefined
                    )
                );
            } catch (error) {
                dispatch(loadResultFailed());
                console.log(error);
            }
        })();
    }, [dispatch]);

    let content;
    if (!fetchConfig && !fetchResult) {
        if (fetchConfigFailed || fetchResultFailed || !result) {
            content = <Empty description="Данных нет" />;
        } else {
            content = (
                <>
                    <Preview />
                    <a
                        href={config.edit_link}
                        className="life-journey-widget__edit"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Button>Редактировать</Button>
                    </a>
                </>
            );
        }
    } else {
        content = (
            <Spin className="life-journey-widget__loading" size="default" tip="Загрузка..." />
        );
    }

    return <div className="life-journey-widget">{content}</div>;
}

export default App;
