import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import integrationReducer from './integration/integrationReducer';
import widgetReducer from './widget/widgetReducer';

const middleware = getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
});

const store = configureStore({
    reducer: {
        integration: integrationReducer,
        widget: widgetReducer,
    },
    middleware,
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;
